import * as React from "react";
import styled from "@emotion/styled";
import PersonalLayout from "../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../components/pages/personal/PersonalBody";
import {Col, Row} from "antd";
import RecommendCard from "../../components/cards/recommendCard";
import Seo from "../../components/seo";
import auth from "../../utils/auth";
import {useRequest} from "ahooks";
import jsonRequest from "../../utils/request/jsonRequest";

const Adverts = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`

const AdvLink = styled.a`
  
`

const Adv = styled.img`
  margin: 0;
  width: 356px;
  height: 154px;
  border-radius: 8px;
  object-fit: cover;
`

const Title = styled.div`
  margin-bottom: 16px;
  line-height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC,sans-serif;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
`

// const recommends = [
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/modao.png").default,
//     name: '墨刀',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
// ]



const Workbench = () => {
  auth.authorize()

  const {data: recommends = []} = useRequest(() => {
    return jsonRequest(`/recommend?groupId=2`).then(rsp => rsp.data?.data ?? [])
  })

  const {data: adverts} = useRequest(() => {
    return jsonRequest(`/advert?position=workbench`).then(rsp => rsp.data?.data ?? [])
  })

  return (<>
    <PersonalLayout selected={'workbench'}>
      <PersonalBody title='工作台'>
        <Adverts>
          {adverts?.map(advert => advert.isRedirect && (
            <AdvLink key={advert.id} href={advert.url} target='_blank'>
              <Adv src={advert.image}/>
            </AdvLink>
          ) ||(
            <Adv key={advert.id} src={advert.image}/>
          ))}
        </Adverts>
        <Title>常用资源</Title>
        <Row gutter={[20, 20]}>
          {recommends?.map((recommend, i) => (
            <Col span={8} key={i}>
              <RecommendCard {...recommend} />
            </Col>
          ))}
        </Row>
      </PersonalBody>
    </PersonalLayout>
  </>)
}

export default Workbench;

export const Head = () => <Seo />
