import * as React from "react";
import styled from "@emotion/styled";
import config from "../../../config";

const A = styled.a`
  text-decoration: none;
`

const Container = styled.div`
  padding: 16px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid rgba(0,0,0,0.12);
  text-decoration: none;
  &:hover {
    box-shadow: ${config.styles.hoverShadow};
    cursor: pointer;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
`

const Image = styled.img`
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  overflow: hidden;
`

const Name = styled.div`
  margin-left: 8px;
  line-height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Heavy, PingFang SC,sans-serif;
  font-weight: 800;
  color: rgba(0,0,0,0.8);
`

const Desc = styled.div`
  margin-top: 12px;
  line-height: 20px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC,sans-serif;
  font-weight: 500;
  color: rgba(0,0,0,0.4);
`

export type RecommendCardProps = {
  id: string | number;
  image?: string;
  name: string;
  desc?: string;
  url?: string;
  isRedirect?: boolean;
}

const RecommendCard = (props: RecommendCardProps) => {
  const {
    image, name, desc, url, isRedirect = false,
  } = props
  const body = (<>
    <Container>
      <Header>
        <Image src={image}/>
        <Name>{name}</Name>
      </Header>
      <Desc>{desc}</Desc>
    </Container>
  </>)

  return isRedirect ? <A href={url} target="_blank">
    {body}
  </A> : body
}

export default RecommendCard;
